import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { CitizenFormData } from "../Citizenonboarding";
import Box from "@mui/material/Box";
interface propsInterface {
    formData: CitizenFormData;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    default_lat: number;
    default_lng: number;
}
export default function Confirmlocationform({
    formData,
    handleInputChange,
    default_lng,
    default_lat,
}: propsInterface) {
    const GOOGLE_MAPS_APIKEY = "AIzaSyCySZqb8qWynQiUpVslZvwyd8-9KhO8ny8";
    const defaults = {
        z: 15,
        lat: default_lat,
        lng: default_lng,
    };
    const containerStyle = {
        width: "100%",
        height: "400px", // Vous pouvez ajuster la hauteur comme vous le souhaitez
    };
    const [latitude, setLatitude] = useState<number | null>(null);
    const [longitude, setLongitude] = useState<number | null>(null);
    const [markerPosition, setMarkerPosition] = useState<{
        lat: number;
        lng: number;
    } | null>(null);

    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAPS_APIKEY,
    });

    const theme = [
        {
            featureType: "all",
            stylers: [
                {
                    saturation: 0,
                },
                {
                    hue: "#EFF2F3",
                },
            ],
        },
        {
            featureType: "road",
            stylers: [
                {
                    saturation: -70,
                },
            ],
        },
        {
            featureType: "transit",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "poi",
            stylers: [
                {
                    visibility: "off",
                },
            ],
        },
        {
            featureType: "water",
            stylers: [
                {
                    visibility: "simplified",
                },
                {
                    saturation: -60,
                },
            ],
        },
    ];

    useEffect(() => {
        // Géocodage de l'adresse
        const address = `${formData.address}, ${formData.city}, ${formData.postal_code}`;
        const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_APIKEY}`;

        fetch(geocodingUrl)
            .then((response) => response.json())
            .then((data) => {
                if (
                    data.results &&
                    data.results[0] &&
                    data.results[0].geometry
                ) {
                    setLatitude(data.results[0].geometry.location.lat);
                    setLongitude(data.results[0].geometry.location.lng);
                    setMarkerPosition({
                        lat: data.results[0].geometry.location.lat,
                        lng: data.results[0].geometry.location.lng,
                    });
                    // Mettre à jour formData avec la nouvelle latitude et longitude
                    handleInputChange(
                        createFakeEvent(
                            "latitude",
                            data.results[0].geometry.location.lat,
                        ),
                    );
                    handleInputChange(
                        createFakeEvent(
                            "longitude",
                            data.results[0].geometry.location.lng,
                        ),
                    );
                } else {
                    console.error("Erreur lors du géocodage 1:", data);
                }
            })
            .catch((error) => {
                console.error("Erreur lors du géocodage 2:", error);
            });
    }, [formData.address, formData.city, formData.postal_code]);

    const handleMapClick = (ev: any) => {
        setMarkerPosition({ lat: ev.latLng.lat(), lng: ev.latLng.lng() });
        const newLatitude = ev.latLng.lat();
        const newLongitude = ev.latLng.lng();
        handleInputChange(createFakeEvent("latitude", newLatitude.toString()));
        handleInputChange(
            createFakeEvent("longitude", newLongitude.toString()),
        );
    };

    const getCurrentLocation = () => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const { latitude, longitude } = position.coords;
                setLatitude(latitude);
                setLongitude(longitude);
                setMarkerPosition({ lat: latitude, lng: longitude });
                // Mettre à jour formData avec la nouvelle latitude et longitude
                handleInputChange(
                    createFakeEvent("latitude", latitude.toString()),
                );
                handleInputChange(
                    createFakeEvent("longitude", longitude.toString()),
                );
            },
            (error) => {
                console.error(
                    "Erreur lors de la récupération de la position actuelle:",
                    error,
                );
            },
        );
    };

    const createFakeEvent = (
        name: string,
        value: string,
    ): React.ChangeEvent<HTMLInputElement> => {
        return {
            target: {
                name,
                value,
            },
        } as React.ChangeEvent<HTMLInputElement>;
    };

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    alignItems: { xm: "flex-start", sm: "center" },
                    justifyContent: "space-between",
                    mb: 5,
                }}
            >
                <Typography
                    variant={"h3"}
                    sx={{
                        color: "#FFB800",
                        fontSize: "24px",
                        fontFamily: "Nunito Sans",
                        fontWeight: 800,
                        px: 3,
                        pb: 1,
                        pl: 0,
                        marginBottom: { xs: "1rem", sm: "0" },
                    }}
                >
                    Confirmez votre position
                </Typography>
                <Button
                    onClick={getCurrentLocation}
                    className={"btn btn-white btn-shadow"}
                >
                    {" "}
                    Utiliser ma position actuelle
                </Button>
            </Box>

            <Grid container spacing={{ md: 2 }}>
                {isLoaded && (
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={
                            latitude && longitude
                                ? { lat: latitude, lng: longitude }
                                : { lat: defaults.lat, lng: defaults.lng }
                        }
                        zoom={defaults.z}
                        options={{
                            styles: theme,
                        }}
                        onClick={handleMapClick}
                    >
                        {markerPosition && <Marker position={markerPosition} />}
                    </GoogleMap>
                )}
            </Grid>
        </React.Fragment>
    );
}
