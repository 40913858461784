import React, {ChangeEvent, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import Apicall from "../../libs/Apicall";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from '@mui/material/Box';
import Alert from "@mui/material/Alert";
import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import Alerttypes from '../../libs/Alerttypes';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Baseinfosform from "./OnboardingFormParts/Baseinfosform";
import AddressForm from "./OnboardingFormParts/AddressForm";
import Alertspreferences from "./OnboardingFormParts/Alertspreferences";
import PasswordForm from "./OnboardingFormParts/PasswordForm";
import Confirmlocationform from "./OnboardingFormParts/Confirmlocationform";
import {currentMunicipalityInterface} from "../../App";
import SubscriptionsDrawer from "../Sidebar/Subscription";
import Footer from "../../components/Layout/Footer/Footer";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

export interface CitizenFormData {
    id?: number;
    city_id: number;
    firstname: string;
    lastname: string;
    phone_home: string | '';
    phone_mobile: string | '';
    email: string;
    address: string;
    apartment: string;
    city: string;
    postal_code: string;
    password: string;
    confirmPassword?: string;
    alert_sms: boolean;
    alert_email: boolean;
    alert_voice: boolean;
    latitude: number | null;
    longitude: number | null;
}
export default function Citizenonboarding({municipality}: propsInterface) {
    const [alerts, setAlerts] = useState<string[]>([]);
    const [registrationSuccessful, setRegistrationSuccessful] = useState<boolean>(false);
    const handleToggleChange = (type: string, value: string) => {
        if (alerts.includes(value)) {
            setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert !== value));
        } else {
            setAlerts((prevAlerts) => [...prevAlerts, value]);
        }
    };

    const selectAllAlerts = () => {
        setAlerts(Alerttypes.map(alert => alert.key));
    };

    const deselectAllAlerts = () => {
        setAlerts([]);
    };

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [formData, setFormData] = useState<CitizenFormData>({
        city_id: municipality.id,
        firstname: params.get('firstname') || '',
        lastname: params.get('lastname') || '',
        phone_home: '',
        phone_mobile: '',
        email: params.get('email') || '',
        address: "",
        apartment: "",
        city: municipality.default_city_name,
        postal_code: "",
        password: "",
        confirmPassword: "",
        alert_sms: false,
        alert_email: true,
        alert_voice: false,
        latitude: null,
        longitude: null,
    });

    const fieldTranslations: Record<keyof CitizenFormData, string> = {
        id: '',
        city_id: "",
        firstname: "Prénom",
        lastname: "Nom",
        email: "Adresse courriel",
        phone_home: "Numéro de téléphone",
        phone_mobile: "Numéro de cellulaire",
        address: "Adresse",
        apartment: "Appartement",
        city: "Ville",
        postal_code: "Code postal",
        password: "Mot de passe",
        confirmPassword: "Confirmation du mot de passe",
        alert_sms: "",
        alert_email: "",
        alert_voice: "",
        latitude: "",
        longitude: "",
    };

    const [alertError, setAlertError] = useState<string | null>(null);
    const navigate = useNavigate();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let formattedValue = value;
        if (name === "phone_home" || name === "phone_mobile") {
            formattedValue = formatPhoneNumber(value);
        }
        setFormData((prev) => (
            {...prev, [name]: formattedValue}
            )
        );
    };

    const formatPhoneNumber = (value: string) => {
        const onlyNums = value.replace(/[^\d]/g, '');
        if (onlyNums.length < 4) return onlyNums;
        if (onlyNums.length < 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
        return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    };


    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Informations de base', 'Adresse', 'Préférences d\'alerte', 'Sécurité', 'Confirmez votre position'];

    // function ScrollTop() {
    //     const { pathname } = useLocation();
    //
    //     useEffect(() => {
    //         window.scrollTo(0,0);
    //     }, [pathname]);
    //
    //     return null;
    // }

    const handleNext = () => {
        // Etape 1: Vérification des champs obligatoires
        let errorMessage = "";
        switch(activeStep) {
            case 0:
                const requiredFields: Array<keyof CitizenFormData> = ["firstname", "lastname", "email"];
                for (let field of requiredFields) {
                    if (!formData[field]) {
                        errorMessage += `${fieldTranslations[field]} est obligatoire. `;
                    }
                }

                if (!formData.phone_home && !formData.phone_mobile) {
                    errorMessage += "Au moins un des champs Numéro de téléphone ou Numéro de cellulaire doit être renseigné. ";
                }

                if (errorMessage) {
                    setAlertError(errorMessage);
                } else {
                    setAlertError(null);
                    // Effectuer le call API...
                    // Etape 2: Appel API pour la validation
                    const apicall = new Apicall();
                    apicall.call('POST', 'register/validate-email-and-phone', {
                        email: formData.email,
                        phone_home: formData.phone_home,
                        phone_mobile: formData.phone_mobile,
                        city_id: formData.city_id
                    }).then((response) => {
                        // Etape 3: Gestion de la réponse de l'API
                        if (response.status === 'ERROR') {
                            setAlertError(String(response.error));
                        } else {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                    }).catch(() => {
                        setAlertError("Une erreur est survenue lors de la validation. Veuillez réessayer.");
                    });
                }
                window.scrollTo(0, 0);
                break;
            case 1:
                // Etape 2: Vérification des champs obligatoires pour l'adresse
                const requiredAddressFields: Array<keyof CitizenFormData> = ["address", "city", "postal_code"];
                for (let field of requiredAddressFields) {
                    if (!formData[field]) {
                        errorMessage += `${fieldTranslations[field]} est obligatoire. `;
                    }
                }

                if (errorMessage) {
                    setAlertError(errorMessage);
                } else {
                    setAlertError(null);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                // ScrollTop();
                break;
            case 3:
                if (!formData.password) {
                    errorMessage = "Le champ mot de passe est obligatoire. ";
                } else if (formData.password !== formData.confirmPassword) {
                    errorMessage = "Le mot de passe et la confirmation du mot de passe ne correspondent pas. ";
                } else if (formData.password.length < 6) {
                    errorMessage = "Le mot de passe doit avoir au moins 6 caractères. ";
                }

                if (errorMessage) {
                    setAlertError(errorMessage);
                } else {
                    setAlertError(null);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                window.scrollTo(0, 0);
                break;
            case 4:
                if (formData.latitude !== null && formData.longitude !== null) {
                    const apicall = new Apicall();
                    apicall.call('POST', 'register/new-citizen', {formData, alerts})
                        .then((response) => {
                            if (response.status === 'OK') {
                                setAlertError(null);
                                setRegistrationSuccessful(true);
                            } else {
                                setAlertError(String(response.error));
                            }
                        })
                        .catch(() => {
                            setAlertError("Une erreur est survenue lors de l'enregistrement. Veuillez réessayer.");
                        });
                } else {
                    setAlertError("Veuillez confirmer votre position.");
                }
                window.scrollTo(0, 0);
                break;
            default:
                //incrementStep();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
        }
    };

    const handleBack = () => {
        // incrementStep();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        window.scrollTo(0, 0);
    };

    return (
        <SubscriptionsDrawer activeStepIdx={activeStep} >
            <Box className='sub-wrapper' sx={{
                    backgroundColor: '#EFF2F3',
                    flexGrow: 1,
                    height:{ xs:'100%', md:'100vh'},
                    overflow: 'auto',
                    marginTop: {xs:'90px', md:'0'},
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Container className={'sub-container'} sx={{ padding: { xs: 2, md: 3 } }}>
                    <Grid container justifyContent="space-between" sx={{pt: 4}}>
                        {/* Image de gauche */}
                        {/*<Grid item xs={6} md={6} sx={{padding:'1rem'}}>*/}
                        {/*    <Box sx={{ width:{xs:'100%', sm:'170px'}, height:{xs:'100%', sm:'100px'}, }}>*/}
                        {/*        <img src="images/logo.png" alt="Logo de gauche" style={{width:'100%', height: '100%', objectFit:'contain'}} />*/}
                        {/*    </Box>*/}
                        {/*</Grid>*/}

                        {/* Image de droite */}
                        <Grid item xs={12} md={12} sx={{ padding:'1rem', display:'flex', alignItems:'flex-start', justifyContent:'center' }}>
                            <Box sx={{ width:{xs:'80%', md:'280px'}, height:{xs:'100%', sm:'97px'}, display:{sm:'flex'},  }}>
                                <img src={`images/${municipality.logo2}`} alt="Logo de droite" style={{ width:'100%', height: '100%', objectFit:'contain' }} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid>
                        {registrationSuccessful ? (
                            <React.Fragment>
                                <Typography variant="h4" sx={{ textAlign: 'center', marginTop: '2rem' }}>
                                    🎉 Félicitations et bienvenue sur Lien Citoyen! 🎉
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                                    Merci d'avoir rejoint notre plateforme. Votre inscription a été effectuée avec succès !
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                                    Lien Citoyen est votre nouveau partenaire pour rester informé(e) en temps réel des événements et alertes dans votre ville. Que ce soit des interdictions de stationnement, des rues fermées, des bris d'aqueduc, des avis d'ébullition de l'eau, et bien d'autres, nous veillons à vous tenir au courant.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                                    Grâce à vos préférences, vous recevrez les alertes directement selon les canaux que vous avez choisis : message texte, courriel ou message vocal. Assurez-vous de vérifier régulièrement vos canaux de communication pour ne manquer aucune information importante.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: '1rem' }}>
                                    Nous sommes ravis de vous avoir parmi nous et nous engageons à vous offrir une expérience optimale. N'hésitez pas à consulter notre section d'aide ou à nous contacter si vous avez des questions ou des suggestions.
                                </Typography>
                                <Typography variant="body1" sx={{ marginTop: '1rem', marginBottom: '2rem', textAlign: 'center' }}>
                                    Restez connecté, restez informé !
                                </Typography>
                                <Typography variant="body2" sx={{ textAlign: 'center' }}>
                                    L'équipe de Lien Citoyen.
                                </Typography>
                                <Box sx={{width:'100%', marginTop:'100px',display:'flex', justifyContent:'center' }}>
                                    <Button startIcon={<ChevronLeftIcon />} className={'btn btn-blue btn-small'} onClick={() => navigate('/')} variant={'contained'} size={'large'}>Retour à l'accueil</Button>
                                </Box>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Box sx={{ marginTop:'50px' }}>
                                    {alertError && (
                                        <Grid item xs={12}>
                                            <Alert severity="error">{alertError}</Alert>
                                        </Grid>
                                    )}
                                    {/* Form */}
                                    {activeStep === 0 && (
                                        <Baseinfosform formData={formData} handleInputChange={handleInputChange} />
                                    )}

                                    {activeStep === 1 && (
                                        <AddressForm formData={formData} handleInputChange={handleInputChange} />
                                    )}

                                    {activeStep === 2 && (
                                        <Alertspreferences canUseVoiceMsg={municipality.can_use_voice_msg} formData={formData} alerts={alerts} handleToggleChange={handleToggleChange} setFormData={setFormData} selectAllAlerts={selectAllAlerts} deselectAllAlerts={deselectAllAlerts} />
                                    )}

                                    {activeStep === 3 && (
                                        <PasswordForm formData={formData} handleInputChange={handleInputChange} />
                                    )}

                                    {activeStep === 4 && (
                                        <Confirmlocationform formData={formData} handleInputChange={handleInputChange} default_lat={municipality.defaultlat} default_lng={municipality.defaultlng} />
                                    )}
                                </Box>

                                {/* Controls */}
                                <Box sx={{marginTop:'75px', display:'flex', justifyContent:'space-between', flexWrap:'wrap'}}>
                                    {/*<Grid container spacing={2} sx={{pt:4}}>*/}
                                        <Grid item>
                                            <Button disabled={activeStep === 0} className={activeStep === 0 ? 'nv' : 'btn btn-small btn-icon btn-icon-arrowl btn-red btn-shadow' } onClick={handleBack}>Retour</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" className={activeStep === steps.length - 1 ? 'btn btn-small btn-blue btn-shadow btn-icon btn-icon-checked' : 'btn btn-small btn-yellow btn-icon btn-icon-arrowr btn-cwhite btn-shadow'} onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Confirmer mon inscription' : 'Étape suivante'}
                                            </Button>
                                        </Grid>
                                    {/*</Grid>*/}
                                </Box>

                            </React.Fragment>
                        )}
                    </Grid>
                </Container>
                <Footer />
            </Box>
        </SubscriptionsDrawer>
    )
}
