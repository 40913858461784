import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {currentMunicipalityInterface} from "../../App";
import Apicall from "../../libs/Apicall";
import Sidebar from "../Sidebar/Sidebar";
import Heroband from "../../components/Hero/Hero";
import AlertDetailled from "../../components/Alerts/Alertdetailled";
import { GoogleMap, useJsApiLoader, Polygon } from '@react-google-maps/api';
import {Alert, Container} from "@mui/material";

interface propsInterface {
    municipality: currentMunicipalityInterface
}

interface alertData {
    "type": string;
    "title": string;
    "description": string;
    "start_date": string;
    "end_date": string|null;
    "send_to_all": boolean;
    "areapoints": string;
    "typename": string;
    "icon": string;
}


interface PosInterface {
    lat: number;
    lng: number;
}
interface mapContainerProps{
    areapoints: PosInterface []
}
function Mapcontainer({areapoints}:mapContainerProps) {
    const GOOGLE_MAPS_APIKEY = 'AIzaSyCySZqb8qWynQiUpVslZvwyd8-9KhO8ny8';
    const mapRef = useRef<google.maps.Map | null>(null);
    const defaults = {
        z: 15,
        lat: 46.25826051840636,
        lng: -72.93927731528319
    };
    const theme = [
        {
            "featureType": "all",
            "stylers": [
                {
                    "saturation": 0
                },
                {
                    "hue": "#EFF2F3"
                }
            ]
        },
        {
            "featureType": "road",
            "stylers": [
                {
                    "saturation": -70
                }
            ]
        },
        {
            "featureType": "transit",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "stylers": [
                {
                    "visibility": "simplified"
                },
                {
                    "saturation": -60
                }
            ]
        }
    ];
    const containerStyle = {
        width: '100%',
        height: '400px' // Vous pouvez ajuster la hauteur comme vous le souhaitez
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_APIKEY
    });

    const getPolygonCenter = (points: PosInterface[]): PosInterface => {
        let latitude = 0;
        let longitude = 0;
        points.forEach(point => {
            latitude += point.lat;
            longitude += point.lng;
        });

        return {
            lat: latitude / points.length,
            lng: longitude / points.length
        };
    }

    const center = getPolygonCenter(areapoints);

    const handleMapLoad = (map: google.maps.Map) => {
        mapRef.current = map;
        const bounds = new window.google.maps.LatLngBounds();
        areapoints.forEach(point => {
            bounds.extend(point);
        });
        map.fitBounds(bounds);
    };

    return (
        <React.Fragment>
            {isLoaded &&
                <GoogleMap
                    onLoad={handleMapLoad}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={defaults.z}
                    options={{
                        styles: theme,
                    }}
                >
                    <Polygon
                        path={areapoints}
                        options={{
                            fillColor: "#FFB800", // changez cela selon votre couleur souhaitée
                            fillOpacity: 0.4,
                            strokeColor: "#FFB800",
                            strokeOpacity: 1,
                            strokeWeight: 3
                        }}
                    />
                </GoogleMap>
            }
        </React.Fragment>
    )
}

export default function OneAlert({municipality}: propsInterface){

    const params = useParams();
    const [error, setError] = useState('');
    const [alert, setAlert] = useState<alertData|null>(null);
    useEffect(() => {
        const apicall = new Apicall();
        apicall.call('GET', `public/alerte/${params.slug}`).then((response) => {
            if(response.status === 'OK'){
                setAlert(response.data);
            }else{
                setError(String(response.error));
            }
        });
    });

    function formatDate(dateString: string | null): string {
        if (!dateString) return '';
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };
        return new Date(dateString).toLocaleDateString('fr-FR', options);
    }

// Dans votre composant :
    let displayDate = '';
    if(alert){
        const startDateFormatted = formatDate(alert.start_date);
        const endDateFormatted = formatDate(alert.end_date);
        displayDate = endDateFormatted
            ? `${startDateFormatted} | Levée le ${endDateFormatted}`
            : startDateFormatted;
    }

    return(
        <Sidebar title={'Page '+municipality.default_city_name} municipality={municipality}>
            {
                alert && !alert.send_to_all ?
                    <Mapcontainer areapoints={JSON.parse(alert.areapoints)} />
                    :
                    <Heroband image={'../images/'+municipality.splash_background} />
            }
            <Container sx={{ mb:{xs:'50%', sm:'100px'}, maxWidth:{xl:'1680px'} }}>
                {
                    error &&
                    <Alert severity="error" variant={'filled'} sx={{my: 1}}>{error}</Alert>
                }
                {
                    alert &&
                    <React.Fragment>
                        <AlertDetailled
                            icon={alert.icon}
                            title={alert.title}
                            date={displayDate}
                            message={alert.description}
                            link={'/'}
                        />
                    </React.Fragment>
                }
            </Container>
        </Sidebar>
    )
}
