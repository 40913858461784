import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ReactComponent as Burger } from "../../assets/images/svg/icon-menu.svg";
import { SvgIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Auth from "../../libs/Auth";
import { currentMunicipalityInterface } from "../../App";
import Footer from "../../components/Layout/Footer/Footer";

const drawerWidth = 225;

interface userInterface {
    id: number;
    created_at: string;
    firstname: string;
    lastname: string;
    email: string;
    role: string;
}

type Props = {
    title: string;
    children: React.ReactNode;
    window?: () => Window;
    currentuser?: userInterface;
    municipality: currentMunicipalityInterface;
};

export default function Sidebar({
    children,
    currentuser,
    municipality,
}: Props) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const logout = () => {
        const auth = new Auth();
        auth.logout();
        navigate("/login");
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigate = useNavigate();

    const drawer = (
        <>
            <Toolbar
                className="side-navigation--header"
                sx={{ padding: "0", position: "relative" }}
            >
                <Box
                    component="img"
                    src={"/images/" + municipality.logo2}
                    sx={{
                        backgroundColor: "#fff",
                        width: { xs: "100%", sm: "100%" },
                        height: { xs: "85%", sm: "100%" },
                        paddingTop: { xs: "1rem", sm: "0" },
                        paddingBottom: { xs: "1rem", sm: "0" },
                        objectFit: "cover",
                    }}
                />
            </Toolbar>
            <List
                className="side-navigation--nav dashboard-navigation"
                component="nav"
                sx={{
                    textTransform: "uppercase",
                    color: "#fff",
                    backgroundColor: "#00004F",
                    flexGrow: 1,
                    height: "90vh",
                    overflow: "auto",
                }}
            >
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText
                            primary={"Accueil"}
                            onClick={() => navigate("/")}
                        />
                    </ListItemButton>
                </ListItem>
                {currentuser && currentuser.id ? (
                    <>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText
                                    primary={"Mon Profil"}
                                    onClick={() => navigate("/mon-profil")}
                                />
                            </ListItemButton>
                        </ListItem>
                        {currentuser.role !== "CITIZEN" && (
                            <ListItem disablePadding>
                                <ListItemButton>
                                    <ListItemText
                                        primary={"Administration"}
                                        onClick={() => navigate("/dashboard")}
                                    />
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText
                                    primary={"Déconnexion"}
                                    onClick={() => logout()}
                                />
                            </ListItemButton>
                        </ListItem>
                    </>
                ) : (
                    <>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText
                                    primary={"Connexion"}
                                    onClick={() => navigate("/login")}
                                />
                            </ListItemButton>
                        </ListItem>
                    </>
                )}
            </List>
            <Box
                component="img"
                src={"/images/logo_dashboard.png"}
                sx={{ px: 3, pb: 3, backgroundColor: "#00004F" }}
            />
        </>
    );

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar
                position="absolute"
                sx={{
                    width: { lg: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
            >
                <Toolbar className="topbar" sx={{ backgroundColor: "#E4E7E8" }}>
                    <Box className="topbar-burger">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { lg: "none" } }}
                        >
                            <SvgIcon component={Burger} inheritViewBox />
                        </IconButton>
                    </Box>

                    <Box className="topbar-city">
                        <Box
                            component="img"
                            src={"/images/" + municipality.logo2}
                            sx={{
                                px: 3.125,
                                pb: 3,
                                backgroundColor: "#fff",
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>

                    <Box className="topbar-content">
                        <div className="topbar-content__banner">
                            <img
                                src="/images/head-banner.png"
                                alt="Inscrivez-vous"
                            />
                            <Box sx={{ margin: "0 21px" }}>
                                <Typography
                                    sx={{ color: "#00004F", fontSize: "20px" }}
                                    className="first"
                                >
                                    <strong>Recevez les alertes</strong> de la
                                    ville de {municipality.default_city_name}
                                </Typography>
                                <Typography className="second">
                                    SMS, Téléphone, courriel, ...
                                </Typography>
                            </Box>
                        </div>
                        {!(currentuser && currentuser.id) && (
                            <div className="topbar-content__action">
                                <button
                                    className="btn btn-yellow"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => navigate("/s-enregistrer")}
                                >
                                    Inscrivez-vous
                                </button>
                            </div>
                        )}
                    </Box>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders"
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: "block", lg: "none" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: "none", lg: "block" },
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            width: drawerWidth,
                        },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                className={"public"}
                sx={{
                    flexGrow: 1,
                    p: 3,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                }}
            >
                <Box
                    className="wrapper"
                    sx={{
                        backgroundColor: "#EFF2F3",
                        flexGrow: 1,
                        overflow: "auto",
                        // marginTop: {xs:'65px', md:'0'},
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {children}
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
}
