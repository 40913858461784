import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer>
            <Box
                className="footer-container"
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", sm: "space-between" },
                }}
            >
                <Typography>
                    ©{currentYear} - Tous droits réservés Lien Citoyen
                </Typography>
                <Typography sx={{}}>
                    <a href="/politique-confidentialite">
                        Politique de confidentialité
                    </a>
                </Typography>
                <Typography sx={{}}>
                    Une application{" "}
                    <b>
                        <a
                            href="https://cognitif.ca/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            cognitif
                        </a>
                    </b>
                </Typography>
            </Box>
        </footer>
    );
}
