import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip"; // <-- Ajout de Tooltip
import Alerttypes from "../../../libs/Alerttypes";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import React from "react";
import { CitizenFormData } from "../Citizenonboarding";
import Box from "@mui/material/Box";

interface propsInterface {
    formData: CitizenFormData;
    alerts: string[];
    handleToggleChange: (type: string, value: string) => void;
    setFormData: React.Dispatch<React.SetStateAction<CitizenFormData>>;
    selectAllAlerts: () => void;
    deselectAllAlerts: () => void;
    canUseVoiceMsg: boolean;
}
export default function Alertspreferences({
    formData,
    alerts,
    handleToggleChange,
    setFormData,
    selectAllAlerts,
    deselectAllAlerts,
    canUseVoiceMsg,
}: propsInterface) {
    return (
        <React.Fragment>
            <Typography
                variant={"h3"}
                sx={{
                    color: "#FFB800",
                    fontSize: "24px",
                    fontFamily: "Nunito Sans",
                    fontWeight: 800,
                    px: 3,
                    pb: 1,
                    pl: 0,
                }}
            >
                Préférences d'alertes
            </Typography>
            <Grid container spacing={{ md: 2 }}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                        Canaux
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{ mb: "35px" }}
                        gutterBottom
                    >
                        Le citoyen désire recevoir les alertes suivantes:
                    </Typography>

                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            label="SMS"
                            labelPlacement="start"
                            className="switch-label"
                            control={
                                <Switch
                                    className="switch-button"
                                    checked={formData.alert_sms}
                                    onChange={() =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            alert_sms: !prev.alert_sms,
                                        }))
                                    }
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            label="Courriel"
                            labelPlacement="start"
                            className="switch-label"
                            control={
                                <Switch
                                    className="switch-button"
                                    checked={formData.alert_email}
                                    onChange={() =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            alert_email: !prev.alert_email,
                                        }))
                                    }
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControlLabel
                            labelPlacement="start"
                            className="switch-label"
                            control={
                                <Switch
                                    className="switch-button"
                                    checked={formData.alert_voice}
                                    onChange={() =>
                                        setFormData((prev) => ({
                                            ...prev,
                                            alert_voice: !prev.alert_voice,
                                        }))
                                    }
                                    disabled={!canUseVoiceMsg}
                                />
                            }
                            label={
                                <>
                                    Appel vocal
                                    {!canUseVoiceMsg && ( // <-- Affiche l'icône d'information si canUseVoiceMsg est false
                                        <Tooltip title="Votre municipalité n’offre pas ce service">
                                            <InfoOutlinedIcon
                                                color="action"
                                                style={{
                                                    marginLeft: 5,
                                                    cursor: "pointer",
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </>
                            }
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                        {" "}
                        Choix des alertes{" "}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{ mb: "35px" }}
                        gutterBottom
                    >
                        {" "}
                        Le citoyen désire recevoir les alertes suivantes:{" "}
                    </Typography>
                    <Box
                        sx={{
                            mb: "35px",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            alignItems: "stretch",
                            gap: "1rem",
                        }}
                    >
                        <Button
                            onClick={selectAllAlerts}
                            className="btn btn-small preference-button"
                            variant="contained"
                            color="primary"
                            style={{
                                padding: "0.625rem 2.125rem",
                                marginBottom: "1rem",
                            }}
                        >
                            Tout sélectionner
                        </Button>
                        <Button
                            onClick={deselectAllAlerts}
                            className="btn btn-small preference-button"
                            variant="outlined"
                            color="primary"
                            style={{
                                padding: "0.625rem 2.125rem",
                                marginBottom: "1rem",
                            }}
                        >
                            Tout désélectionner
                        </Button>
                    </Box>
                    {Alerttypes.map((alert) => (
                        <Grid item key={alert.key}>
                            <FormControlLabel
                                className="switch-label"
                                labelPlacement="start"
                                control={
                                    <Switch
                                        className="switch-button"
                                        id={`alert-${alert.key}`}
                                        checked={alerts.includes(alert.key)}
                                        onChange={() =>
                                            handleToggleChange(
                                                "alert",
                                                alert.key,
                                            )
                                        }
                                    />
                                }
                                label={alert.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
